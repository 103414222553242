import { Injectable } from '@angular/core';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ICurrentOrganisation } from '../models/organisation.model';
import { PaginatedMachines } from '../models/analytics.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';

export const OPERATIONS_URL = 'v2/operations';
export const OPERATIONS_URL_V1 = 'v1/operations';

@Injectable()
export class OperationsService {
  private operationsUrl = `${environment.apiBaseUrl}/${OPERATIONS_URL}`;
  private operationsUrlV1 = `${environment.apiBaseUrl}/${OPERATIONS_URL_V1}`;

  private alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  constructor(private translate: TranslateService, private serviceCall: ServiceCall, private alertService: AlertService) {}

  getOrganisationByOrgId(organizationId: string): Observable<ICurrentOrganisation> {
    return this.serviceCall.get(`${this.operationsUrlV1}/organizations/by-org-id/${organizationId}`).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
        return throwError(error);
      })
    );
  }

  inviteUserToOrganization(auth0Id: string, email: string, role: string): Observable<ICurrentOrganisation> {
    return this.serviceCall.post(`${this.operationsUrlV1}/organizations/${auth0Id}/invitation`,
    {
      email,
      role
    }).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
        return throwError(error);
      })
    );
  }

  getAllOrganizationMachines(organizationId: string, page: number, size: number, sort: 'asc' | 'desc'): Observable<PaginatedMachines> {
    return this.serviceCall
      .get(`${this.operationsUrl}/organizations/${organizationId}/machines?page=${page}&size=${size}&sort=${sort}`)
      .pipe(
        map((response) => response.data),
        catchError((error) => {
          this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
          return throwError(error);
        })
      );
  }

  addMachine(organizationId: string, serial: string) {
    return this.serviceCall.post(`${this.operationsUrl}/organizations/${organizationId}/machines`, { serial }).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
        return throwError(error);
      })
    );
  }

  deleteMachine(organizationId: string, uuid: string) {
    return this.serviceCall
      .post(`${this.operationsUrl}/organizations/${organizationId}/machines/${uuid}/delete`, {
        consent: true,
      })
      .pipe(
        map((response) => response.data),
        catchError((error) => {
          this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
          return throwError(error);
        })
      );
  }
}
